import { graphql, navigate, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import Button from "../../UI/Button"
import DetailsForm from "./components/DetailsForm"
import FormModal from "./components/FormModal"
import ThanksApproved from './components/ThanksApproved'
import ThanksNotApproved from './components/ThanksNotApproved'
import useTeacherSignup, { TeacherSignupProvider } from "./useTeacherSignup"

const Wrapper = styled.div`
  position: relative;
  min-height: 400px;
  > :first-child {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`

const Content = styled.div`
  max-width: 560px;
  margin: 0 auto;
  z-index: 1;
  padding-top: 40px;
  padding-bottom: 100px;
`

const TeacherSignup = () => {
  const { step, next, updateFormData, submit, approved } = useTeacherSignup()
  const { file } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "creatables-green.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `)

  const onDetailsSubmit = async (data: any) => {
    await submit(data)
    next()
  }

  return (
    <>
    <Wrapper>
      <GatsbyImage image={getImage(file) as IGatsbyImageData} alt="" />
      <Content>
        {step === 0 && (
          <FormModal
            title="How many students do you have?"
            options={[
              <Button
                key="1"
                theme="confirm"
                size="regular"
                onClick={() => navigate("/parents")}
              >
                Less than 6
              </Button>,
              <Button key="2" theme="primary" size="regular" onClick={next}>
                6 or more
              </Button>,
            ]}
          />
        )}
        {step === 1 && (
          <FormModal
            title="Is your school already subscribed?"
            options={[
              <Button
                key="1"
                theme="confirm"
                size="regular"
                onClick={() => {
                  updateFormData({ schoolAlreadySubscribed: true })
                  next()
                }}
              >
                Yes
              </Button>,
              <Button
                key="2"
                theme="primary"
                size="regular"
                onClick={() => {
                  updateFormData({ schoolAlreadySubscribed: false })
                  next()
                }}
              >
                No
              </Button>,
            ]}
          />
        )}
        {step === 2 && <DetailsForm onSubmit={onDetailsSubmit} />}
        {step === 3 && approved && <ThanksApproved />}
        {step === 3 && !approved && <ThanksNotApproved />}
      </Content>
    </Wrapper>
    </>
  )
}

export default (props) => 
<TeacherSignupProvider>
  <TeacherSignup {...props} />
</TeacherSignupProvider>
