import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  background-color: #fff;
  border: 2px solid ${(props: any) => props.theme.primary};
  padding: 35px 25px;
  text-align: center;
  border-radius: 13px;
  max-width: 430px;
  margin: 0 auto;
  > :first-child {
    color: ${(props: any) => props.theme.secondary};
    font-size: 36px;
    line-height: 44px;
    font-weight: bold;
    margin-bottom: 30px;
  }
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    margin-bottom: 20px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

interface IProps {
  title: string
  options: any[]
}

const FormModal = (props: IProps) => (
  <Wrapper>
    <div>{props.title}</div>
    <Options>{props.options.map(o => o)}</Options>
  </Wrapper>
)

export default FormModal
