import { graphql } from 'gatsby'
import React from 'react'
import TeacherSignup from '../../components/pages/TeacherSignup'
import SEO from '../../components/SEO'
import TeachersLayout from '../../layouts/Teachers'
import Apollo from '../../providers/Apollo'

const TeacherSignupPage = ({ data }) => <TeachersLayout>
    <Apollo>
      <SEO title='Home' canonical={`${data.site.siteMetadata.siteUrl}`} />
      <TeacherSignup />
    </Apollo>
  </TeachersLayout>

export const query = graphql`
  {
    site {
      siteMetadata {
        siteUrl
      }
    }
    strapiHomepage {
      ZooHero {
        title
      }
    }
  }
`

export default TeacherSignupPage
