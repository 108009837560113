import { useApolloClient } from "@apollo/client/react"
import { useLocation } from '@reach/router'
import gql from "graphql-tag"
import { createContainer } from 'lib/unstated'
import { useState } from "react"
import useHubspotTracking from "../../../hooks/useHubspotTracking"

export interface IDetails {
  name: string
  email: string
  countryCode: string
  schoolName: string
  nbStudents: number
  requestQuote: boolean
  specialOffer: boolean
  yearGroup: number
  schoolAlreadySubscribed: boolean
}

const TeacherSignupState = () => {
  const client = useApolloClient()
  const { identify } = useHubspotTracking()
  const [step, setStep] = useState(0)
  const [approved, setApproved] = useState(false)
  const [formData, setFormData] = useState<Partial<IDetails>>({})
  const location = useLocation()
  const next = () => setStep(s => Math.min(s + 1, 3))
  const previous = () => setStep(s => Math.max(s - 1, 0))

  const updateFormData = (payload: Partial<IDetails>) => {
    setFormData((data: Partial<IDetails>) => ({
      ...data,
      ...payload,
    }))
  }

  const submit = async (details: any): Promise<boolean> => {
    const allData = { ...formData, ...details }
    const { data } = await client.mutate({
      mutation: gql`
        mutation($details: RegisterTeacherParentProductInput!) {
          registerTeacherParentProduct(details: $details)
        }
      `,
      variables: {
        details: {
          name: `${allData.firstName} ${allData.lastName}`,
          email: allData.email,
          countryCode: allData.countryCode,
          schoolName: allData.schoolName,
          nbStudents: parseInt(allData.nbStudents, 10),
          requestQuote: false,
          specialOffer: false,
          yearGroup: parseInt(allData.yearGroup, 10),
          schoolAlreadySubscribed: allData.schoolAlreadySubscribed,
        },
      },
    })
    identify({
      email: allData.email,
      firstname: allData.firstName,
      lastname: allData.lastName,
      country: allData.countryCode,
      company: allData.schoolName,
      contact_type: "Teacher",
    })
    try {
      if (typeof window !== 'undefined') {
        window.postMessage({
          event: 'TeacherSignup',
          payload: {
            email: allData.email,
            firstName: allData.firstName,
            lastName: allData.lastName,
            country: allData.countryCode,
            company: allData.schoolName,
            yearGroup: allData.yearGroup,
            nbStudents: parseInt(allData.nbStudents, 10),
            schoolAlreadySubscribed: allData.schoolAlreadySubscribed,
          }
        }, location.origin)
      }
    } catch (err) {
      console.error(err)
    }
    setFormData(allData)
    setApproved(data.registerTeacherParentProduct || false)
    return data.registerTeacherParentProduct
  }

  return {
    step,
    next,
    previous,
    formData,
    updateFormData,
    approved,
    setApproved,
    submit,
  }
}

export const TeacherSignupStateContainer = createContainer(TeacherSignupState)

export const TeacherSignupProvider = TeacherSignupStateContainer.Provider

export default TeacherSignupStateContainer.useContainer
