/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import "@fontsource/libre-baskerville" // Defaults to weight 400 with all styles included.
import "@fontsource/rammetto-one"
import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { ThemeProvider } from "styled-components"
import Footer from "../components/Footer"
import GlobalStyle from "../components/GlobalStyles"
import Header from "../components/Header"
import SEO from '../components/SEO'
import BareLayout from "./Bare"
import { createTheme, DEFAULT_THEME } from '../styles/Theme'

const Content = styled.div`
  > :first-child {
    padding-top: 50px;
  }
`

const TeachersLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const THEME = createTheme({
    primary: '#8AC55C',
    secondary: '#006736'
  })

  return (
    <BareLayout>
      <ThemeProvider theme={THEME}>
        <SEO />
        <Header />
        <Content>
          { children }
        </Content>
        <Footer />
      </ThemeProvider>
    </BareLayout>
  )
}

TeachersLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TeachersLayout
