import { Button } from 'nzk-react-components'
import React from "react"
import styled from "styled-components"
import useTeacherSignup from '../useTeacherSignup'

const Wrapper = styled.div`
  background-color: #fff;
  border: 2px solid ${(props: any) => props.theme.primary};
  padding: 35px 25px;
  text-align: center;
  border-radius: 13px;
  max-width: 430px;
  margin: 0 auto;
  > :first-child {
    color: ${(props: any) => props.theme.secondary};
    font-size: 36px;
    line-height: 44px;
    font-weight: bold;
    margin-bottom: 30px;
  }
`

const Content = styled.div``

const ThanksApproved = () => {
  const { formData } = useTeacherSignup()

  if (parseInt(`${formData.nbStudents}`, 10) > 55) {
    return <Wrapper>
      <div>Thank you!</div>
      <Content>
        <p>
          We have set up one class for you to trial the program.
        </p>
        <p>To set up more classes, please book a demo with a member of our education team.</p> 
        <a
          href="https://meetings.hubspot.com/nightzookeeper/demo-15-min"
          target="_blank"
          rel="noreferrer"
        >
          <Button theme="primary" size="regular">
            Book a demo
          </Button>
        </a>
        <p>
          We can't wait for you to start your 14 day trial!
        </p>
        <p>You'll need to verify your email address.</p>
        <p>We've just sent you an email to {formData.email}, so please check your inbox (and your Spam folder) to verify your account and get started.</p>
      </Content>
    </Wrapper>
  }
  return <Wrapper>
    <div>Thank you!</div>
    <Content>
      <p>
        We can't wait for you to start your 14 day trial!
      </p>
      <p>First, you'll need to verify your email address.</p>
      <p>We've just sent you an email to {formData.email}, so please check your inbox (and your Spam folder) to verify your account and get started.</p>
    </Content>
  </Wrapper>
}

export default ThanksApproved
