import VimeoEmbed from 'components/UI/VimeoEmbed'
import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  background-color: #fff;
  border: 2px solid ${(props: any) => props.theme.primary};
  padding: 35px 25px;
  text-align: center;
  border-radius: 13px;
  max-width: 630px;
  margin: 0 auto;
  > :first-child {
    color: ${(props: any) => props.theme.secondary};
    font-size: 36px;
    line-height: 44px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  a {
    color: ${(props: any) => props.theme.secondary};
    text-decoration: underline;
  }
`

const Content = styled.div`
  ul {
    list-style-type: number;
    text-align: left;
  }
  li {
    margin: 10px 0px;
  }
`

const Title = styled.div`
  color: ${(props: any) => props.theme.secondary};
  font-size: 36px;
  line-height: 44px;
  font-weight: bold;
  margin-bottom: 30px;
`

const SubTitle = styled(Title)`
  font-size: 26px;
  line-height: 34px;
  margin-bottom: 20px;
`

const Info = styled.p`
  margin-top: 30px;
  font-size: 13px;
`

const ThanksNotApproved = () => (
  <Wrapper>
    <Title>Thank you!</Title>
    <Content>
      <p>
        Our team are verifying your details and will set up your free 14 day trial within 24 hours.
      </p>
      <p>You’ll receive an email with  instructions on how to get started, so keep an eye on your inbox! (Please check your spam folder in case our email doesn’t appear.)</p>
      <SubTitle>While you're waiting...</SubTitle>
      <ul>
        <li>
          <p>Introduce your students to the Night Zoo with this short video!</p>
          <VimeoEmbed vimeoId='554801531' />
        </li>
        <li>
          <p>Watch this short tutorial on how to use Night Zookeeper in the classroom.</p>
          <VimeoEmbed vimeoId='678228681' />
        </li>
        <li>Take a look at our printable teaching resources <a href="/shop/categories/teaching-resources" target='_blank'>here</a></li>
      </ul>
      <Info>If you do not hear from us within 24 hours, you can contact <a href="mailto:teachers@nightzookeeper.com">teachers@nightzookeeper.com</a> to request a status update on your trial account.</Info>
    </Content>
  </Wrapper>
)

export default ThanksNotApproved
